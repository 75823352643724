import React from 'react';
import PropTypes from 'prop-types';
import Header from "components/Header";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faMailForward, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';

// const darkModeAtom = atomWithStorage( 'darkMode', false );
// const [ darkMode, setDarkMode ] = useAtom( darkModeAtom );
// const toggleDarkMode = () => setDarkMode( !darkMode );


const Layout = ({ children }) => {
  return (
    <>
      <div className={ 'global_wrap' }>
        <Header/>
        <main> { children } </main>
      </div>

      <aside className={ "footer_sign_area" }>
        <Link legacyBehavior href={ '/' }>
          <a> MAIL </a>
        </Link> |
        {/*<a href={ '/nft/' }> NFT </a> |*/ }
        <Link legacyBehavior href={ '/contact/' }>
          <a>
            <FontAwesomeIcon style={ { marginRight: "0.5rem" } } icon={ faEnvelope }/> 問い合わせ
          </a>
        </Link>

      </aside>

      <div style={ { display: "flex", justifyContent: "center", margin: "1rem auto" } }>
        <ConnectButton
          label="Sign in"
          chainStatus="icon"
          showBalance={ false }
          // accountStatus="avatar"
          accountStatus={ {
            smallScreen: 'avatar',
            largeScreen: 'full',
          } }

        ></ConnectButton>
      </div>

      <p style={ { textAlign: "center", fontSize: "0.5rem", color: "#fff" } }> OSAKA 2022
        <img src="/icon/icons8/takoyaki-64.png" width={ "24" } height={ "24" }/> takoyaki
      </p>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node
};


