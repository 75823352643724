// import type, { AppProps } from 'next/app';
import { SWRConfig } from 'swr';
import fetchJson from "lib/fetchJson";
import '../styles/globals.css';
import Layout from '../components/Layout';
import { useAccount, useEnsName } from 'wagmi'

/* rainbowkit with walletConnect */
import { getDefaultWallets, Chain, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import '@rainbow-me/rainbowkit/styles.css';

/* wagumi */
import { createConfig, WagmiConfig, WagmiProvider } from 'wagmi';
import { createPublicClient, http } from 'viem'
import { goeri, mainnet, zor, avalanche, optimism, polygon, sepolia, arbitrum } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

/** wagmi default setting */
const queryClient = new QueryClient()
const client = createPublicClient( {
  chain: mainnet,
  transport: http(),
} );

const wagmi_config = createConfig( {
    chains: [ avalanche,mainnet, optimism, sepolia ], publicClient: client, transports: {
      [ mainnet.id ]: http(),
      [ optimism.id ]: http(),
      [ sepolia.id ]: http(),
    }
  }
)

/* rainbowkit with walletConnect default setting */
const wagmi_config_with_rainbowkit_config = getDefaultConfig( {
  appName: 'kwonwe',
  projectId: '4323717fedf52aee4489f493b7c5d164',
  chains: [ mainnet ],
  transports: {
    [ mainnet.id ]: http(),
  },
} )


const useDefaultProvider = false;
const wagmi_provider_config =
  useDefaultProvider ?
    wagmi_config :
    wagmi_config_with_rainbowkit_config

function USOM( { Component, pageProps } ) {
  return (
    <SWRConfig
      value={ {
        fetcher: fetchJson,
        onError: ( err ) => {
          console.error( 'ERR:', err );
        }
      } }
    >
      <WagmiProvider config={ wagmi_provider_config }>
        <QueryClientProvider client={ queryClient }>
          <RainbowKitProvider>
            <Layout>
              <Component { ...pageProps } />
            </Layout>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>

    </SWRConfig>
  );
}

export default USOM;


export function Profile() {
  const { address } = useAccount()
  const { data, error, status } = useEnsName( { address } )
  if ( status === 'pending' ) return <div>Loading ENS name</div>
  if ( status === 'error' )
    return <div>Error fetching ENS name: { error.message }</div>
  return <div>ENS name: { data }</div>
}
