import React from 'react';
import LayoutMode from './LayoutMode';

const Header = () => {

  return (
    <header>
      <nav>
        <p className={ 'logo_wrap' }>
          <a className={ 'logo' } href={ '/' }> </a>
          <LayoutMode></LayoutMode>
        </p>
      </nav>
    </header>
  );
};

export default Header;
