import React from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const locationWord = atomWithStorage( 'USOM_LOCATION_WORD', false );

const Header = () => {

  const [ USOM_LOCATION_WORD, setLocationWord ] = useAtom( locationWord );
  const toggleLocationWord = () => setLocationWord( !USOM_LOCATION_WORD );

  return (
    <>
      <style jsx global>{ `
        .logo_word_button {
          padding: 0;
          border: 0;
          color: #fff;
          background: unset;
          font-size: inherit;
        }
       ` } </style>
      <button className={ "logo_word_button" } onClick={ toggleLocationWord } suppressHydrationWarning>
        { USOM_LOCATION_WORD ? 'OSAKA' : 'TOKYO' }
      </button>
    </>
  )
    ;
};

export default Header;
