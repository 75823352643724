export default async function fetchJson(input, init) {
  try {
    const response = await fetch( input, init );
    const data = await response.json();

    if ( response.ok ) {
      return data;
    }

    const error = new Error( response.statusText );
    error.response = response;
    error.data = data;

    // throw error;
    throw new FetchError( {
      message: response.statusText,
      response,
      data
    } );
  } catch ( error ) {
    if ( !error.data ) {
      error.data = { message: error.message };
    }
    throw error;
  }
}

export class FetchError extends Error {
  response;
  data;

  constructor({ message, response, data }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super( message );

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if ( Error.captureStackTrace ) {
      Error.captureStackTrace( this, FetchError );
    }

    this.name = "FetchError";
    this.response = response;
    this.data = data ?? { message: message };
  }
}
